<template>
  <div class="contact">
    <!-- 关于我们 -->
    <div class="banner">
      <img src="../../assets/img/contact/banner.png" alt="" />
      <!-- <div class="WebMain ">
                <div class="breadcrumb">
                    <span class="p15" v-for="(item, index) in breadcrumbList" :key="item.id">{{ item.name }} <i
                            v-show="index !== breadcrumbList.length - 1" class="el-icon-arrow-right"></i></span>
                </div>
            </div> -->
    </div>
    <div class="container">
      <div class="WebMain">
        <div class="menuList">
          <div class="item" v-for="item in list" :key="item.id">
            <p
              :class="item.id === activeId ? 'active p18' : 'p18'"
              @click="changeActiveId(item.id)"
            >
              <span>{{ item.title }}</span>
              <i class="el-icon-d-arrow-right"></i>
            </p>
            <!-- || activeId === 10 || activeId === 12 -->
            <div
              v-if="
                showContent && (item.id === activeId) "
            >
              <div class="newsList phone" v-if="showList">
                <div
                  class="item"
                  v-for="item in newsList"
                  :key="item.id"
                  @click="toDetail(item)"
                >
                  <!-- {{ item.title }} -->
                  <p class="p18">
                    <span class="title">{{ item.title }}</span>
                    <!-- <span class="more">MORE</span> -->
                  </p>
                  <p class="created p16">{{ item.created }}</p>
                  <p class="summary p16">{{ item.summary }}</p>
                </div>
              </div>
              <div class="content phone" v-else v-html="article.content"></div>
            </div>
          </div>
        </div>
        <div class="newsList web" v-if="showList">
          <div
            class="item"
            v-for="item in newsList"
            :key="item.id"
            @click="toDetail(item)"
          >
            <!-- {{ item.title }} -->
            <p class="p18">
              <span class="title">{{ item.title }}</span>
              <!-- <span class="more">MORE</span> -->
            </p>
            <p class="created p16">{{ item.created }}</p>
            <p class="summary p16">{{ item.summary }}</p>
          </div>
        </div>
        <div class="content web artical" v-else v-html="article.content"></div>
      </div>
    </div>
  </div>
</template>
 
<script>
import { articleDetailAPI, articleListAPI } from "@/api/article/article";
import { articleTypeListAPI, articleTypeDetailAPI } from "@/api/article/type";
export default {
  data() {
    return {
      showContent: false,
      list: [
        { id: 1, title: "公司简介" },
        { id: 2, title: "联系我们" },
      ],
      article: {},
      activeId: 1,
      newsList: [],
      showList: true,
    };
  },
  methods: {
    // 获取文章列表
    async getArticleList(val, val2) {
      // this.categoryId = this.$route.params.id
      let info = {
        pageNum: 1,
        pageSize: 100,
        categoryId: this.activeId,
      };
      const res = await articleListAPI(info);
      if (res.code === 200) {
        if (val && val2) {
          if (this.showContent === true && val === val2) {
            this.showContent = false;
          } else {
            this.showContent = true;
          }
        }
        this.newsList = res.data.list;
        if (this.list.length > 0) {
          // this.activeId = this.list[0].id;
          // this.getDetail();
        }
      } else {
        this.$message({
          type: "info",
          showClose: true,
          message: "列表获取失败!",
        });
      }
    },
    // 获取文章分类详情
    async getTypeDetail() {
      const res = await articleTypeDetailAPI({ id: this.$route.params.id });
      if (res.code === 200) {
        this.list = res.data.children;

        if (this.list.length > 0) {
          if (this.$route.query.id) {
            this.activeId = Number(this.$route.query.id);
            // this.getArticleList();
          } else {
            this.activeId = this.list[0].id;
          }
          //   this.activeId = this.list[0].id;
          this.getArticleList();
        }
      } else {
        this.$message({
          type: "info",
          showClose: true,
          message: "详情获取失败!",
        });
      }
    },
    // 点击查看文章详情
    toDetail(val) {
      this.showList = false;
      this.getDetail(val.id);
      
    },
    changeActiveId(val) {
      let val2 = this.activeId;
      this.activeId = val;
     
      if (val === 10 || val === 12) {
        this.showList = false;
        this.showContent = true;
        let id = 34;
        if (val === 12) {
          id = 35;
        }
        this.getDetail(id);
      } else {
        this.showList = true;
        this.getArticleList(val, val2);
      }
    },
    // 获取文章详情
    async getDetail(val) {
      // console.log(this.$route.params.id)
      const res = await articleDetailAPI({ id: val });
      if (res.code === 200) {
        this.article = res.data;
        let metaInfo = {
          title: this.article.metaTitle,
          keywords: this.article.metaKeywords,
          description: this.article.metaDescription,
        };
        this.$store.commit("CHANGE_META_INFO", metaInfo);
      } else {
        this.$message({
          type: "info",
          showClose: true,
          message: "详情获取失败!",
        });
      }
      // console.log(this.typeList)
    },
  },
  created() {
    this.getTypeDetail();
    if (this.$route.query.articleId) {
      this.showList = false;
      this.getDetail(Number(this.$route.query.articleId));
    }
  },
  watch: {
    $route(to, from) {
      this.getArticleList();
    },
  },
};
</script>
  
<style lang="scss" scoped>
.contact {
  .banner {
    position: relative;

    img {
      width: 100%;
      height: 95px;
    }
  }

  .container {
    margin: 50px 0px;
    .WebMain {
      display: flex;
      // flex-wrap: wrap;
      // height: 100%;
    }

    .menuList {
      min-height: 600px;
      // height: 100%;
      width: 250px;
      // border-right: 2px dashed #bbbbbb;
      padding-left: 20px;
      // padding-right: 20px;
      box-sizing: border-box;
      background: #FFFFFF;
      box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.1);
      border-radius: 6px;
      .phone {
        display: none;
      }

      p {
        width: 100%;
        height: 50px;
        line-height: 50px;
        margin-bottom: 10px;
        padding-right: 20px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;

        cursor: pointer;

        i {
          color: transparent;
          line-height: 50px;
        }

        span {
          color: #595959;
          // color: #888888;
        }
      }

      .active {
        background: linear-gradient(270deg, #e9f7ff 0%, #ffffff 100%);

        i {
          text-align: right;
          color: var(--custom-color);
        }

        span {
          // color: #000000;
          color: var(--second-color);
        }
      }
    }

    .newsList {
      width: 950px;
      background: #FFFFFF;
      margin-left: 30px;
      box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.1);
      border-radius: 6px;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 20px;
      .item {
        border-bottom: 1px dashed #bbbbbb;
        margin-bottom: 30px;
        min-height: 150px;
        p {
          margin-bottom: 10px;
          display: flex;
          justify-content: space-between;
          cursor: pointer;
        }
        .title {
          display: -webkit-box;
          overflow: hidden;
          word-break: break-all;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          color: var(--second-color);
          //   width: 90%;
        }
        .more {
          // color: var(--custom-color);
          // font-weight: 600;
        }
        .created {
          color: #666;
        }
        .summary {
          display: -webkit-box;
          overflow: hidden;
          word-break: break-all;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          line-height: 25px;
        }
      }
    }
    .content {
      width: 950px;
      margin-left: 30px;
      background: #FFFFFF;
      box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.1);
      border-radius: 6px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 25px;
     
      ::v-deep p {
        img {
          max-width: 100% !important;
          margin-top: 20px;
          margin-bottom: 20px;
        }
      }
    }s
    .artical{
      padding-top: 20px;
    }
  }
}
@media screen and (max-width: 750px) {
  .contact .container .menuList {
    width: 100%;
    border: 0;
    padding: 0;

    .phone {
      display: block;
      width: 100%;
      padding-left: 0;
      border-top: 1px solid #eee;
      border-bottom: 1px solid #eee;
      padding: 20px 0;
      margin-bottom: 20px;
    }
  }
  .web {
    display: none;
  }
}
</style>